.App {
  background: url('./assets/bg.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  direction: rtl;
  font-family: 'Varela Round' !important;
  font-size: 24px;
  align-items: stretch;
  text-align: center;
  line-height: 1.15;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
}

body {
  overflow: hidden;
}

@font-face {
  font-family: 'Varela Round';
  src: url('./assets/fonts/VarelaRound-Regular.eot');
  src: url('./assets/fonts/VarelaRound-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/VarelaRound-Regular.woff') format('woff'),
      url('./assets/fonts/VarelaRound-Regular.ttf') format('truetype'),
      url('./assets/fonts/VarelaRound-Regular.svg#VarelaRound-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

img {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

h1 {
  font-size: 36px;
  color: rgb(235, 166, 27);
  margin-bottom: 16px;
  margin-top: 0px;
  text-shadow: 2px 2px #000;
  text-align: center;
  font-weight: 900;
  background-color: #00000045;
  border-radius: 20px;
  padding: 4px;
}

h2 {
  font-size: 28px;
  margin-bottom: 12px;
  margin-top: 2px;
  margin-right: 12px;
  text-shadow: 2px 2px #000;
  text-align: start;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.colored {
  color: rgb(235, 166, 27);
  text-shadow: 2px 2px #000;
}

.bold {
  font-weight: 900;
}

.glass {
  background-image: linear-gradient(135deg, rgba(66, 66, 66, 0.3), rgba(33, 33, 33, 0.6), rgba(55, 55, 55, 0.2));
  backdrop-filter: blur(6px);
  border-radius: 20px;
  padding: 16px;
  margin: 8px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  vertical-align: middle;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  box-sizing: border-box;
}

.segment:not(:first-of-type) {
  margin-top: 20px;
}

.detail {
  border-radius: 20px;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.detail:nth-of-type(even) {
  background-color: #0000002a;
}

.blinking {
  animation: blink 2s linear infinite;
}

.red {
  background-color: rgb(180, 0, 0, 0.75);
}

.greyedout {
  opacity: 0.3;
}



@keyframes blink {
  40% {
    background-color: rgb(180, 0, 0, 0.75);
  }
}